import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"


export default function Legal({ data }: any) {
  const { title, subtitle } = data.header.frontmatter

  const md = data.header.html

  const seo = {
    metaTitle: title,
    metaDescription: subtitle,
  }

  return (
    <Layout navLight={false}>
      <Seo seo={seo} lang={data.locales.edges[0].node.language} />
      <section className="relative py-16">
        <div
          id="legal"
          className="container"
          dangerouslySetInnerHTML={{ __html: md }}
        ></div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: markdownRemark(
      frontmatter: { slug: { eq: "legal/static" }, lang: { eq: $language } }
    ) {
      id
      html
      frontmatter {
        lang
        slug
        title
        subtitle
      }
      internal {
        contentFilePath
      }
    }
  }
`
